import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Helmet from "react-helmet";
import { MarkdownLayout } from '../../partials/MarkdownLayout';
import { Colors, Fonts, MaxWidths } from "../../materials";
import { Container, Label, LinkGatsby, Heading, LinkArrowGatsby, Box, LinkAnchor, Code, ButtonIcon, Input, List, ListItem, Note, Text, TextBold } from "../../elements";
import { SnippetButtonIcon } from '../../partials/SnippetButtonIcon';
import { SnippetInputWithoutLabel } from '../../partials/SnippetInputWithoutLabel';
import { SnippetNavigationLandmarksAriaLabel } from '../../partials/SnippetNavigationLandmarksAriaLabel';
import { SnippetRegionLandmarks } from '../../partials/SnippetRegionLandmarks';
export const _frontmatter = {
  "seoTitle": "aria-label - examples and best practices | Aditus",
  "seoURL": "https://www.aditus.io/aria/aria-label/",
  "seoImage": "https://www.aditus.io/social/aria/aria-label.png",
  "seoImageAlt": "Text reading 'aria-label' with the Aditus logo next to it",
  "title": "aria-label",
  "description": "The aria-label should be used to provide a text alternative to an element that has no visible text on the screen. See its applications and learn how to use to make your website more accessible.",
  "lead": ["The <code>aria-label</code> should be used to provide a text alternative to an element that has no visible text on the screen."],
  "toc": {
    "nodes": [{
      "selfId": "definition",
      "selfText": "Definition"
    }, {
      "selfId": "how-to-use",
      "selfText": "How to use"
    }, {
      "selfId": "when-to-use",
      "selfText": "When to use"
    }, {
      "selfId": "examples",
      "selfText": "Examples",
      "nodes": [{
        "selfId": "example-1-icon-button",
        "selfText": "Icon Button"
      }, {
        "selfId": "example-2-labelless-input",
        "selfText": "Labelless Input"
      }, {
        "selfId": "example-3-multiple-navigation-landmarks",
        "selfText": "Multiple Navigation Landmarks"
      }, {
        "selfId": "example-4-identifying-region-landmarks",
        "selfText": "Identifying region landmarks"
      }]
    }, {
      "selfId": "comparision",
      "selfText": "Comparision",
      "nodes": [{
        "selfId": "aria-label-vs-aria-labelledby",
        "selfText": "vs aria-labelledby"
      }, {
        "selfId": "aria-label-vs-title",
        "selfText": "vs title"
      }]
    }, {
      "selfId": "frameworks",
      "selfText": "Frameworks",
      "nodes": [{
        "selfId": "react-and-aria-label",
        "selfText": "React"
      }, {
        "selfId": "angular-and-aria-label",
        "selfText": "Angular"
      }, {
        "selfId": "vue-and-aria-label",
        "selfText": "Vue"
      }]
    }, {
      "selfId": "common-mistakes",
      "selfText": "Common Mistakes",
      "nodes": [{
        "selfId": "Over-using",
        "selfText": "Over-using it"
      }]
    }]
  }
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <MarkdownLayout {...props} renderAfter={() => <>
      <nav aria-labelledby='related-topics'>
      <Box mt={6} />
      <Box bg={Colors.oc.gray[0]} py={8}>
        <Container maxWidth='l'>
          <Heading>Related Topics</Heading>
          <Box mt={6} />
          <List display='flex' m={-4}>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/aria/aria-current/' css={{
              display: 'block',
              textDecoration: 'none',
              textAlign: 'left',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[1],
                border: `5px solid ${Colors.oc.blue[9]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>ARIA</Label>
                    <Text as='code' fontSize={5} css={{
                    fontFamily: Fonts.mono.family,
                    fontWeight: Fonts.mono.weight,
                    textDecoration: 'underline'
                  }}>
                      aria-current
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/patterns/multiple-navigation-landmarks/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>Patterns</Label>
                    <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                      Multiple <code>{`<nav>`}</code>
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/patterns/breadcrumbs/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>Patterns</Label>
                    <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                      Breadcrumbs
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
          </List>
        </Container>
      </Box> 
    </nav>
    </>} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Definition`}</h2>
    <Box mt={4} mdxType="Box" />
    <p><inlineCode parentName="p">{`aria-label`}</inlineCode>{` is an attribute defined in the `}<LinkAnchor href='https://www.w3.org/TR/wai-aria/#aria-label' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`WAI-ARIA`}</LinkAnchor>{`
specification. This specification extends native HTML, allowing you
to change the way an HTML element is "translated" into the accessibility tree.`}</p>
    <Box mt={6} mdxType="Box" />
    <p>{`By default, an HTML element will use its text content as the accessibility
label. If an element has `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`, the accessible name becomes the string that
it's passed in the attribute.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`<button>send</button>
// accessible name: send
  
<button aria-label="send email">send</button>
// accessible name: send email`} language='html' mdxType="Code" />
    </Box>
    <Box mt={6} mdxType="Box" />
    <h2>{`How to use`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`You can make use of `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` by adding it to certain `}<sup className='md-footnote' id="valid-elements-anchor"><a href="#valid-elements"><span className='visually-hidden'>{`Footnote `}</span>{`1)`}</a></sup>{` HTML elements.`}</p>
    <Box mt={6} mdxType="Box" />
    <Box display='flex' flexWrap='wrap' bg={Colors.oc.gray[1]} maxWidth='copy' mt={-2} mdxType="Box">
  <Box flex='1 1 auto' display='flex' alignItems='center' justifyContent='center' py={4} px={6} aria-hidden='true' mdxType="Box">
    <ButtonIcon aria-label="Menu" mdxType="ButtonIcon">
      <svg viewBox="0 0 24 24">
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill='currentColor' />
      </svg>
    </ButtonIcon>
  </Box>
  <Box flex='1 1 auto' mdxType="Box">
    <Code codeString={`<button
  aria-label="menu"
  class="burger"></button>`} language='html' mdxType="Code" />
  </Box>
    </Box>
    <Box mt={6} mdxType="Box" />
    <p>{`VoiceOver on OSX will read this button as `}<strong parentName="p">{`"menu, button"`}</strong>{`, as
shown in the image below.`}</p>
    <Box mt={4} mdxType="Box" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2dbc636d1fa1dcf370b9f168ad532377/21fc5/vo-menu-button.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.503267973856207%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsSAAALEgHS3X78AAAAe0lEQVQI12OYM3PG//6erv8Tenv+T+rv+z+hp/t/c33d/5aGuv/NROLWpob/NZXl/1cuW/qfoaKk6H9yXMz/zNRkME5NjP8fGRIIxEFE4+iwkP8BXh7/u9vb/jOATE9NiPuflZbyPzMlCWxodnoqSTg3Mx3sqOlTJ/8HADXKiYj0oeTAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Screenshot of VoiceOver on OSX  when focusing the button in the example",
            "title": "Screenshot of VoiceOver on OSX  when focusing the button in the example",
            "src": "/static/2dbc636d1fa1dcf370b9f168ad532377/7ba82/vo-menu-button.png",
            "srcSet": ["/static/2dbc636d1fa1dcf370b9f168ad532377/3e5d0/vo-menu-button.png 148w", "/static/2dbc636d1fa1dcf370b9f168ad532377/7ecfb/vo-menu-button.png 295w", "/static/2dbc636d1fa1dcf370b9f168ad532377/7ba82/vo-menu-button.png 590w", "/static/2dbc636d1fa1dcf370b9f168ad532377/21fc5/vo-menu-button.png 612w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Box mt={6} mdxType="Box" />
    <Note title='1) What elements can be used with aria-label' id='valid-elements' pt={6} mdxType="Note">
  <Box mt={1} mdxType="Box" />
  <List maxWidth='copy' ml={4} css={{
        listStyleType: 'disc'
      }} mdxType="List">
    <ListItem mt={2} mdxType="ListItem"><strong>interactive elements</strong>: such as <code>a</code> (when <code>href</code> attribute is present),
          <code>audio</code> and <code>video</code> (when <code>controls</code> attribute is present), <code>input</code>, <code>select</code>, <code>button</code>, <code>textarea</code>.</ListItem>
    <ListItem mt={2} mdxType="ListItem"><strong>implicit landmark elements</strong>: such as <code>header</code>, <code>footer</code>, <code>nav</code>, <code>main</code>, <code>aside</code>,
          <code>section</code>, and <code>form</code>.</ListItem>
    <ListItem mt={2} mdxType="ListItem"><strong>explicit landmark elements</strong>: such as an element with <code>role="navigation"</code>.</ListItem>
    <ListItem mt={2} mdxType="ListItem"><strong>widget role elements</strong>: such as an element with <code>role="dialog"</code> or <code>role="tooltip"</code>
(there are 27 widget roles in ARIA 1.1).</ListItem>
    <ListItem mt={2} mdxType="ListItem"><strong><code>iframe</code> and <code>img</code> elements.</strong></ListItem>
  </List>
  <Box mt={5} mdxType="Box" />
  <Text mdxType="Text">More information on: <LinkAnchor href={"https://www.w3.org/TR/WCAG21/"} target="_blank" rel="noopener noreferrer" href='https://developer.paciellogroup.com/blog/2017/07/short-note-on-aria-label-aria-labelledby-and-aria-describedby/' mdxType="LinkAnchor">Short note on aria-label, aria-labelledby, and aria-describedby</LinkAnchor>.</Text>
    </Note>
    <Box mt={6} mdxType="Box" />
    <h2>{`When to use`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`You should use it when you have non-textual indication of an element's purpose,
but still need to provide text alternates for users who use assistive technology,
such as screen readers.`}</p>
    <Box mt={6} mdxType="Box" />
    <Note title='Tip: Labelling best practice' mdxType="Note">
      <Text mdxType="Text">You don't need to add "button" in the label as screen readers already announce an element's role.</Text>
  <Box mt={3} mdxType="Box" />
  <Code codeString={`// ⛔ BAD: "send button, button"
<button aria-label="send button">...<button>
  
// ✅ Good: "send, button"
<button aria-label="send">...<button>`} language='html' mdxType="Code" />
    </Note>
    <Box mt={6} mdxType="Box" />
    <h2>{`Examples`}</h2>
    <h3>{`Example 1: Icon Button`}</h3>
    <Box mt={4} mdxType="Box" />
    <SnippetButtonIcon mdxType="SnippetButtonIcon" />
    <Box mt={6} mdxType="Box" />
    <h3>{`Example 2: Labelless input`}</h3>
    <Box mt={4} mdxType="Box" />
    <SnippetInputWithoutLabel mdxType="SnippetInputWithoutLabel" />
    <Box mt={6} mdxType="Box" />
    <h3>{`Example 3: Multiple Navigation Landmarks`}</h3>
    <Box mt={4} mdxType="Box" />
    <SnippetNavigationLandmarksAriaLabel mdxType="SnippetNavigationLandmarksAriaLabel" />
    <Box mt={2} mdxType="Box" />
    <LinkArrowGatsby to='/patterns/multiple-navigation-landmarks/' mdxType="LinkArrowGatsby">See "Multiple Navigation Landmarks" pattern</LinkArrowGatsby>
    <Box mt={6} mdxType="Box" />
    <h3>{`Example 4: Identifying region landmarks`}</h3>
    <Box mt={4} mdxType="Box" />
    <SnippetRegionLandmarks mdxType="SnippetRegionLandmarks" />
    <Box mt={6} mdxType="Box" />
    <h2>{`Comparision`}</h2>
    <h3><inlineCode parentName="h3">{`aria-label`}</inlineCode>{` vs. `}<inlineCode parentName="h3">{`aria-labelledby`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`When label text is visible on screen, you should use `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Box minWidth={['auto', 'auto', 500]} mdxType="Box">
    <Code codeString={`// ⛔ BAD: using aria-label when text
// is visible on screen
<nav aria-label="Related Content">
  <h2>Related Content</h2>
  <ul>
    ...List on links here...
  </ul>
</nav>`} language='html' mdxType="Code" />
  </Box>
    </Box>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Box minWidth={['auto', 'auto', 500]} mdxType="Box">
    <Code codeString={`// ✅ Good: using aria-labelledby when text
// is visible on screen
<nav aria-labelledby="nav-title">
  <h2 id='nav-title'>Related Content</h2>
  <ul>
    ...List on links here...
  </ul>
</nav>`} language='html' mdxType="Code" />
  </Box>
    </Box>
    <Note title='Note' mdxType="Note">
  <Text mdxType="Text">
    If an element has both <code>aria-labelledby</code> and <code>aria-label</code>,
    the value of <code>aria-labelledby</code> will be used in the text alternative computation.
  </Text>
    </Note>
    <h3><inlineCode parentName="h3">{`aria-label`}</inlineCode>{` vs. `}<inlineCode parentName="h3">{`title`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`The `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute shows as a tooltip when
the mouse goes over the element. While it can be useful for
people using the mouse, it will not be available to keyboard-only users. Note that while
`}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute is listed in the text-alternative computation algorithm, it might not be supported
in some combinations of screen-reader/browsers (at the time of writting, IE 11 and NVDA).`}</p>
    <Box mt={4} mdxType="Box" />
    <p>{`In short, If you find yourself using the `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute to provide
additional information, it's probably better to either use `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` or think
of an alternative (e.g.: disclosure additional information to users such as a tooltip).`}</p>
    <Box mt={6} mdxType="Box" />
    <h2>{`Frameworks`}</h2>
    <Box mt={4} mdxType="Box" />
    <h3>{`React and `}<inlineCode parentName="h3">{`aria-label`}</inlineCode></h3>
    <p>{`React, in particular JSX, supports all `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{` attributes. Contrary to other
HTML attributes, ARIA attributes in jsx should be hyphen-cased as they are in plain HTML.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`{/* Static string */}
<button aria-label="Send">...</button>
\n{/* Dynamic string */}
<button aria-label={labelVariable}>...</button>`} language='jsx' mdxType="Code" />
    </Box>
    <Box mt={6} mdxType="Box" />
    <h3>{`Angular and `}<inlineCode parentName="h3">{`aria-label`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`In Angular, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{` attributes are treated like any other
HTML attributes and can either be binded to static attributes or
to dynamic variables (using the `}<inlineCode parentName="p">{`attr`}</inlineCode>{` prefix).`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`// Static string
<button aria-label="Send">...</button>
\n// Dynamic string
<button [attr.aria-label]="labelVariable">...</button>`} language='html' mdxType="Code" />
    </Box>
    <Box mt={6} mdxType="Box" />
    <h3>{`Vue.js and `}<inlineCode parentName="h3">{`aria-label`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`In Vue, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{` attributes are also treated like any other
HTML attributes and can either be binded to static attributes or
to dynamic variables (using the `}<inlineCode parentName="p">{`:aria-label`}</inlineCode>{` prefix).`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`<!-- Static string -->
<button aria-label="Send">...</button>
\n<!-- Dynamic string -->
<button :aria-label="labelVariable">...</button>`} language='html' mdxType="Code" />
    </Box>
    <Box mt={6} mdxType="Box" />
    <h2>{`Common mistakes`}</h2>
    <Box mt={4} mdxType="Box" />
    <h3>{`Over-using it`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`If the element has text content, you `}<strong parentName="p">{`DON'T`}</strong>{` need to add `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`, as
it is redundant.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Box minWidth={['auto', 'auto', 500]} mdxType="Box">
    <Code codeString={`// ⛔ BAD: over-using aria-label
<button aria-label="Send">Send</button>`} language='html' mdxType="Code" />
  </Box>
    </Box>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Box minWidth={['auto', 'auto', 500]} mdxType="Box">
    <Code codeString={`// ✅ GOOD: use it only when needed it
<button>Send</button>`} language='html' mdxType="Code" />
  </Box>
    </Box>
    <Box mt={6} mdxType="Box" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      